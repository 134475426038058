.container-carrosel{
  display: block;
  margin: 0;
  padding: 10vw;
  font-family: 'Bebas Neue';
}

.container-carrosel-titulo{
  display: flex;
  margin-bottom: 5vw;
  justify-content: start;
  justify-items: center;
  align-items: center;
}

.container-carrosel-titulo img{
  height: 100%;
  width: 2.5vw;
  
}

.container-carrosel-titulo h2{
  font-size: 3vw;
  margin: 0;
  padding-left: 0.5vw;
  color: var(--cor-primaria);
}

.sub-container-galeria{
  display: flex;
  width: 80vw;
}

.sub-sub-container-galeria1{
  padding-left: 5vw;
}

.sub-sub-container-galeria2{
  display: flex;
}