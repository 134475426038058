.container-desenvolvedor{
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    
}

.container-desenvolvedor p{
    margin: 0;
    padding-right: 1vw;
}

.container-desenvolvedor img{
    height: 100%;
    width: 8vw;
}

.texto-desenvolvido{
    font-size: 2vw;
}

@media (max-width:768px){
    .container-desenvolvedor img{
    height: 100%;
    width: 12vw;
}
.texto-desenvolvido{
    font-size: 4vw;
}
}