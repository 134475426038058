.container-pree-footer{
    background: var(--cor-primaria);
    color:#fff;
    font-family: 'Bebas Neue';
}

.sub-container-pree-footer{
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
}
.item-sub-container-pree-footer{
    flex: 1;
    padding: 1vw;
}

.item-sub-container-pree-footer-maior{
    flex: 2;
}



.item-sub-container-pree-footer-maior-ul{
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
    
}

.sub-item-sub-container-pree-footer{
    padding-left: 2vw;
    font-size: 1.5vw;
}
.container-pree-footer-direitos{
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    padding: 2vw;
}

a{
    text-decoration: none;
    list-style-type: none;
    color: inherit;
}


.container-arrow-up {
  position: fixed;
  background-color: var(--cor-primaria);
  color: white;
  font-size: 3vw;
  border-radius: 50%;
  bottom: 50px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.6vw;
  height: 5.6vw;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 99999;
}

.container-arrow-up:hover {
  background-color: var(--cor-secundaria);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  transform: translateY(-5px);
}

