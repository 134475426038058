.video-container {
  position: relative;
  height: 87vh;
  width: 100%;
}

.video-frame {
  width: 100%;
  height: 100%;
  display: block;
}

.video-image {
  display: none;
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media (max-width: 768px) {
  .video-frame {
    display: none;
  }

  .video-image {
    display: block;
    width: 100%;
    height: auto;
  }
  .video-container {
  position: relative;
  height: auto;
  width: 100%;
}
}
