.container-propostas {
  background: linear-gradient( #1c3862, #0d2345);
  padding-left:9vw ;
  padding-top: 3.5vw;
  padding-bottom: 5vw;
  font-family: 'Bebas Neue';
  height: auto;
}

.container-titulo-propostas {
  display: flex;
  align-items: center;
  justify-content: start;
  justify-items: center;
  color: #fff;
  margin-bottom: 2.5vw;
}

.proposta-titulo-img {
  margin: 0;
  width: 2.5vw;
  height: 100%;
}

.proposta-titulo-titulo {
  margin: 0;
  font-size: 3vw;
  margin-left: 0.1vw;
}

.sub-container-proposta {
  display: flex;
  
}

.sub-container-proposta-img {
  border-radius: 1.5vw;
  height: 35vw;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow:rgba(13, 35, 69, 1) ;
  
}


.container-propostas-propostas{
  background-color: var(--cor-primaria);
  border-radius: 1.5vw;
  border-width: thin;
  border-style: solid;
  border-color: var(--cor-secundaria);
  padding: 1.5vw;
  padding-right: 1vw;
  margin-right: 6.5vw;
  margin-left: 6.5vw;
  margin-top: 7.0vw;
  margin-bottom: 9.0VW;
  
}

.sub-container-propostas-propostas-titulo{
  display: flex;
  margin-bottom: 2vw;
  margin-right: 15vw;
  width: 100%;
  
}

.container-propostas-propostas-titulo{
  color: #fff;
  margin: 0;
  margin-left: 1vw;
  font-size: 2vw;
  font-family: 'Bebas Neue';
  
} 


.img-sub-sontainer-proposta{
  width: 1.5vw;
  height: 100%;
}

.container-propostas-propostas-texto{
  color: #dedede;
  margin-top: 1vw;
  font-size: 1.5vw;
  font-family: 'Roboto';
}

.carousel-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel-content {
  background-color: var(--cor-primaria);
  border-radius: 1.5vw;
  border-width: thin;
  border-style: solid;
  border-color: var(--cor-secundaria);
  padding: 1.5vw;
  padding-right: 1vw;
  margin-right: 6.5vw;
  margin-left: 6.5vw;
  margin-top: 7.0vw;
  margin-bottom: 2vw;
  max-width: 100%;
}

.carousel-controls {
  display: flex;
  justify-content: space-between;
  width: 10vw;
  margin-top: 1.5vw;
}

.carousel-arrow {
  background-color: var(--cor-primaria);
  color: #fff;
  border: none;
  font-size: 2vw;
  cursor: pointer;
  padding: 0.5vw;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  margin-top: 1vw;
}

.indicator {
  width: 1.5vw;
  height: 1.5vw;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 0.5vw;
  cursor: pointer;
}

.indicator.active {
  background-color: var(--cor-secundaria);
}


@media (max-width: 768px) {
  .sub-container-proposta {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sub-container-proposta-img {
    width: 80%; /* Ajuste o tamanho da imagem para caber melhor na coluna */
    height: auto;
    margin-bottom: 2vw;
  }

  .carousel-content {
    margin-right: 0;
    margin-left: 0;
    width: 100%; /* Para ocupar a largura total do container */
  }

  .container-propostas {
    padding-left: 3vw;
    padding-right: 3vw;
  }

  /* Título principal das propostas */
.proposta-titulo-titulo {
  margin: 0;
  font-size: 3.5vw; /* Aumentei de 3vw para 3.5vw */
  margin-left: 0.1vw;
}

/* Título de cada proposta */
.container-propostas-propostas-titulo {
  color: #fff;
  margin: 0;
  margin-left: 1vw;
  font-size: 4vw; /* Aumentei de 2vw para 2.5vw */
  font-family: 'Bebas Neue';
}

/* Texto de cada proposta */
.container-propostas-propostas-texto {
  color: #dedede;
  margin-top: 1vw;
  font-size: 3.6vw; /* Aumentei de 1.5vw para 2vw */
  font-family: 'Roboto';
}
.img-sub-sontainer-proposta{
  width: 4vw;
}

}
