.container-chamada{
    background-color: var(--cor-primaria);
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
    font-family: 'Bebas Neue';
    height: auto;
}


.container-chamada h1{
    color: #fff;
    font-size: 7vw;
    margin: 0;
}

.numero-voto{
    color:var(--cor-secundaria);
    font-size: 9vw;
}

.img-chamada{
    width: 20vw;
}


@media(max-width){
    .container-chamada h1{
    font-size: 7.6vw;
    
}

.numero-voto{
    font-size: 9.6vw;
}

}