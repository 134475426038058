.container-noticias {
  background-color: var(--cor-primaria);
  font-family: 'Bebas Neue';
  height: auto;
  padding-bottom: 2vw
}

.noticias-img-partido,
.container-titulo-contribuioes {
  display: flex;
  align-items: center;
}

.noticias-img-partido {
  justify-content: end;
}

.noticias-img-partido img {
  width: 8vw;
}

.container-titulo-contribuioes {
  width: 100%;
  justify-content: start;
  padding-left: 9vw;
  margin-bottom: 2vw;
}

.container-titulo-contribuioes img {
  width: 2.5vw;
}

.titulo-noticias {
  font-size: 3vw;
  color: #fff;
  margin: 0;
}

.container-noticias-card {
  display: flex;
  justify-content: space-around;
  padding: 0 5vw 4vw;
}

.noticias-card {
  margin-top: 2vw;
  background-color: #1b3660;
  border-radius: 2vw;
  height: 30vw;
  width: 25vw;
  z-index: 9999;
}

.noticias-imagem-card {
  width: 100%;
  height: 15vw;
  border-radius: 2vw 2vw 0 0;
}

.noticias-texto-card,
.noticias-ano-card {
  color: #fff;
  padding: 2vw;
}

.noticias-texto-card {
  font-size: 2vw;
}

.noticias-ano-card {
  color: #7695c5;
  font-size: 1.3vw;
  padding-top: 1vw;
  font-family: 'Roboto', sans-serif;
}

.nav-button {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 4vw;
  cursor: pointer;
  z-index: 10000;
}

.nav-button:focus {
  outline: none;
}

.nav-button:hover {
  color: var(--cor-secundaria);
}

.noticias-indicadores {
  display: flex;
  justify-content: center;
  margin-top: -1vw;
}

.indicador-bolinha {
  width: 1vw;
  height: 1vw;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 0.5vw;
  transition: background-color 0.3s ease;
}

.indicador-bolinha.ativo {
  background-color: var(--cor-secundaria);
}

/* Estilos para telas menores */
@media (max-width: 768px) {
  .container-noticias {
    height: 75vw;
  }

  .noticias-texto-card {
    font-size: 1.8vw;
    margin: 0;
  }
}
