.container-header {
  background-color: var(--cor-primaria);
  height: 13vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-header-img {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 0.7vw;
}

.header-img-logo {
  width: 15vw;
}

.nav-lista {
  color: #fff;
  flex: 3;
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}

.nav-lista-item {
  font-size: 2vw;
}

.nav-lista-item:hover {
  color: var(--cor-secundaria);
  transition: color 0.3s;
}

.header-container-redes-sociais {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-container-redes-sociais-titulo {
  font-size: 1vw;
  color: var(--cor-secundaria);
  text-align: center;
  margin-bottom: 5px;
}

.header-redes-sociais {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-left: 0;
}

.header-redes-sociais-icone {
  margin-left: 10%;
}

@media (max-width: 768px) {
  .nav-lista {
    justify-content: space-around;
  }
}
