.container-chamada-dois{
    background-color: #fff;
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: space-between;
    font-family: 'Bebas Neue';
    height: auto;
}


.container-chamada-dois h1{
    color: var(--cor-primaria);
    font-size: 5vw;
    margin: 0;
}

.numero-voto{
    color:var(--cor-secundaria)
}

.img-chamada{
    width: 18vw;
}

.img-chamada2{
    width: 20vw;
    padding-top: 1.8vw;
}