.container-sobre {
    padding: 3vw;
    height: auto;
    overflow: hidden;
}

.container-titulo-sobre {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 2%;
    margin-bottom: 2vw;
}

.img-icone-sobre {
    width: 2.5vw;
    height: auto;
}

.titulo-sobre {
    font-size: 3vw;
    margin: 0;
    margin-left: 0.5%;
    font-family: 'Bebas Neue';
}

.container-titulo-sobre-texto {
    display: flex;
    align-items: center;
    margin-bottom: 3vw;
    font-family: 'Bebas Neue';
    flex-wrap: wrap;
}

.titulo-sobre-texto {
    font-size: 3.7vw;
    margin-top: 2vw;
    display: flex;
    align-items: center;
}

.img-sobre-texto {
    width: 3vw;
    height: auto;
    margin-left: 1vw;
}

.texto-biografia {
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
}

@media (max-width: 768px) {
    .titulo-sobre-texto {
        font-size: 5vw;
    }

    .img-sobre-texto {
        width: 5vw;
    }

    .texto-biografia {
        font-size: 1rem;
    }
}
